import React, { FC } from 'react'

type CancelCircleProps = { color: string; onClick: () => void }

export const CancelCircle: FC<CancelCircleProps> = ({ color, onClick }) => {
  return (
    <>
      <svg
        onClick={() => onClick()}
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
          stroke={color}
          fill="none"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
          stroke={color}
          d="M8 16L16 8"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
          stroke={color}
          d="M8 8L16 16"
        ></path>
      </svg>
    </>
  )
}
