import React, { FC } from 'react'
import styles from '../styles'

type GridItemProps = {
  gridRowSpan?: number
  gridColSpan?: number
  borderRadius?: string
}
export const GridItem: FC<GridItemProps> = ({
  gridRowSpan,
  gridColSpan,
  borderRadius,
  children,
}) => {
  return (
    <div css={styles.gridItem(gridRowSpan, gridColSpan, borderRadius)}>
      {children}
    </div>
  )
}
