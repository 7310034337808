import React, { useState } from 'react'
import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
// src imports
import { WorkPageQuery_Manual } from '@/../manual-graphql-types'
import { useThemeHook } from '@/hooks/useThemeHook'
import { Grid } from '@/app/molecules/grid/grid'
import { GridItem } from '@/app/molecules/grid/components/gridItem'
import { Text } from '@/app/atoms/text/text'
import { Overlay } from '@/app/molecules/overlay/overlay'
import { Carousel } from '@/app/organisms/carousel/carousel'
import { SEO } from '@/app/organisms/layout/components/seo'

type WorkProps = {
  data: WorkPageQuery_Manual
}

const Work: React.FC<WorkProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const images = data.allSanityImageGallery.edges[0].node.images
  const imageURLs = images.map(image => image.asset.url)
  const theme = useThemeHook()
  const handleSetPhoto = (index: number) => {
    setIsOpen(true)
    setActiveIndex(index)
  }
  return (
    <>
      <SEO pageTitle="Our Work" />
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: ${theme.foreground};
          padding: 16px;
        `}
      >
        <div
          css={css`
            width: 100%;
          `}
        >
          <Text type="heroTitle">Our Work</Text>
        </div>
        <Grid columnSize={140} rowSize={100} gap={16}>
          {images.map((image, index) => {
            const aspectRatio = image.asset.fluid.aspectRatio
            return (
              <GridItem
                key={image.asset.id}
                gridColSpan={2}
                gridRowSpan={aspectRatio > 1 ? 2 : 4}
              >
                <div
                  onClick={() => handleSetPhoto(index)}
                  css={css`
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                  `}
                >
                  <Img
                    style={{ width: '100%', height: '100%' }}
                    fluid={image.asset.fluid}
                  />
                </div>
              </GridItem>
            )
          })}
          {isOpen && (
            <Overlay onClose={() => setIsOpen(false)}>
              <Carousel
                images={imageURLs}
                height={670}
                initialPage={activeIndex}
              />
            </Overlay>
          )}
        </Grid>
      </div>
    </>
  )
}

export const query = graphql`
  query WorkPage {
    allSanityImageGallery {
      edges {
        node {
          images {
            asset {
              id
              fluid(maxWidth: 420) {
                ...GatsbySanityImageFluid
              }
              url
            }
          }
        }
      }
    }
  }
`

export default Work
