import { css } from '@emotion/core'

export default {
  overlay: css`
    backdrop-filter: saturate(180%) blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    overflow: hidden;
    margin-top: 30px;
  `,
}
