import { css } from '@emotion/core'

export default {
  grid: (
    gap: number,
    columnSize: number,
    rowSize: number,
    maxWidth?: number,
    height?: string,
    overflow?: string,
  ) => css`
    display: grid;
    max-width: ${maxWidth && maxWidth}px;
    width: 100%;
    grid-gap: ${gap}px;
    grid-template-columns: repeat(auto-fit, minmax(${columnSize}px, 1fr));
    grid-auto-rows: minmax(${rowSize}px, auto);
    grid-auto-flow: dense;
    height: ${height && height};
    overflow: ${overflow && overflow};
  `,
  gridItem: (
    gridRowSpan?: number,
    gridColSpan?: number,
    borderRadius?: string,
  ) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    grid-column-end: span ${gridColSpan};
    grid-row-end: span ${gridRowSpan};
    border-radius: ${borderRadius};
  `,
}
