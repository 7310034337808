import { CancelCircle } from '@/app/atoms/icons/cancel'
import { css } from '@emotion/core'
import { motion } from 'framer-motion'
import React, { FC } from 'react'
import styles from './styles'

export type OverlayPropTypes = {
  onClose: () => void
}
export const Overlay: FC<OverlayPropTypes> = ({ children, onClose }) => {
  return (
    <motion.div css={styles.overlay}>
      <div
        css={css`
          position: absolute;
          right: 16px;
          top: 40px;
          cursor: pointer;
          z-index: 1000;
        `}
      >
        <CancelCircle color="#FFF" onClick={() => onClose()} />
      </div>
      {children}
    </motion.div>
  )
}
