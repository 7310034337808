import React, { FC } from 'react'
import styles from './styles'

type GridProps = {
  columnSize: number
  rowSize: number
  gap: number
  maxWidth?: number
  height?: string
  overflow?: string
}
export const Grid: FC<GridProps> = ({
  columnSize,
  rowSize,
  gap,
  maxWidth,
  children,
  height,
  overflow,
}) => {
  return (
    <section
      css={styles.grid(gap, columnSize, rowSize, maxWidth, height, overflow)}
    >
      {children}
    </section>
  )
}
